body {
  font-family: "Roboto", sans-serif;
}

.loading {
  opacity: .25;
}

.nav-bar-header {
  background-color: rgba(60,106,176,1.0);
}

.nav-bar-brand {
  font-family: "trebuchet ms";
  font-weight: bold;
  color:white;
}

.nav-bar-brand:hover {
  color: white;
}

.nav-bar-brand:focus {
  color: white;
}

.btn-hermes {
  background-color: rgba(60,106,176,0.9)!important;
  color: white!important;
  min-width: 75px;
}

.btn-hermes:hover {
  background-color: rgba(60,106,176,0.9);
  color: white;
}

.btn-hermes-series {
  min-width: 95px;
}

.btn-hermes:focus {
  background-color: rgba(60,106,176,0.9);
  color: white;
}
.hermes-link-color {
  color: rgba(60,106,176,0.9);
}

.hermes-nav-link {
  color: rgba(60,106,176,0.9);
  /* background-color: lightgray; */
  /* border-radius: 10px; */
}

.hermes-nav-link-active {
  color: rgba(60,106,176,0.9);
  background-color: lightgray;
  /* border-radius: 10px; */
}

.upload-csv {
  max-width: 500px;
}

.hermes-header {
  font-family: roboto, sans-serif;
}

.error-msg {
  font-size: .95rem;
  /* background-color: rgb(225, 224, 224); */
  min-height: 40px;
  /* border-radius: 25px; */
}

.standings-small-titles {
  font-size: .85rem;
}

.standings-medium-font {
  font-size: .85rem;
}

.standings-large-items {
  font-size: 3rem;
}

.standings-detail {
  background-color: white;
}

.hermes-logo-img {
  width: 35%;
}

.chartWrapper {
  width: 1200px;
  max-width: 1000px;
  overflow-x: scroll;
}

.race-list {
  white-space: nowrap;
}

.chartAreaWrapper {
  height: 500px;
}

@media screen and (max-width: 600px) {

  .chartWrapper {
    width: 1200px;
    max-width: 300px;
    overflow-x: scroll;
  }
  
  .chartAreaWrapper {
    height: 300px;
  }
  
}


@media screen and (min-width: 600px) {

  .chartWrapper {
    width: 1200px;
    max-width: 600px;
    overflow-x: scroll;
  }
  
  .chartAreaWrapper {
    height: 300px;
  }
  
}

@media screen and (min-width: 768px) {

  .chartWrapper {
    width: 1200px;
    max-width: 750px;
    overflow-x: scroll;
  }
  
  .chartAreaWrapper {
    height: 300px;
  }
  
}

@media screen and (min-width: 992px) {

  .chartWrapper {
    width: 1200px;
    max-width: 900px;
    overflow-x: scroll;
  }
  
  .chartAreaWrapper {
    height: 300px;
  }
  
}

@media screen and  (min-width:1200px) {

  .hermes-nav-link {
    background-color: rgba(60, 106, 176, 0.9);
    color: lightgray;
    border-radius: 0;
  }
  .error-msg {
    font-size: 1.2rem;
    /* background-color: inherit; */
  }

  .hermes-nav-link-active {
    color: white;
    background-color: rgba(60, 106, 176, 0.9);
    /* background-color: lightgray; */
    /* border-radius: 10px; */
  }
  .standings-small-titles {
    font-size: 1.0rem;
  }
  .standings-medium-font {
    font-size: 1rem;
  }
  .hermes-logo-img {
    width: 25%;
  }
  .chartWrapper {
    width: 1200px;
    max-width: 800px;
    overflow-x: scroll;
  }
  
  .chartAreaWrapper {
    height: 500px;
  }
}

/* @media screen and (min-width: 1400px) {

  .chartWrapper {
    width: 1400px;
    max-width: 1400px;
    overflow-x: scroll;
  }
  
  .chartAreaWrapper {
    height: 600px;
  }
  
} */

/* .active {
  color:blue;
} */

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
